#overlay-auth-wrapper {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10001; /* Specify a stack order in case you're using a different order for other elements */
    .auth-popup {
        width: 100%;
        max-width: 350px;
        padding: 10px;
        margin-left: auto;
        margin-right: auto;
        right: 0;
        left: 0;
        border: 0px solid;
        outline: 1px solid gray;
        border-radius: 5px;
        background: white;
        position: absolute;
        z-index: 10001;
        top: 50%;
        transform: translateY(-50%);
        height: 30%;
        min-height: 450px;
        transition: 300ms;
        -webkit-transition: 300ms;
        box-shadow: 2px 2px 2px 2px rgba($color: #000000, $alpha: 0.2);

        @media screen and (max-width: 800px) {
            margin-left: 4px;
            margin-right: 4px;
            width: calc(100% - 8px);
        }

        &.wrapContent{
            height: auto;
            min-height: fit-content;
        }
    }

    .middle-container {
        width: 100%;
        margin: auto;

        .input-row {
            margin-top: 10px;
        }

        h3 {
            text-align: center;

            &.header-text{
                color : teal;
            }
        }

        input[type='email'],
        input[type='password'] {
            width: 100%;
            height: 42px;
            box-sizing: border-box;
            border: 1px solid gray;
            border-radius: 3px;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            outline: 0px;
            outline-color: transparent;
            outline-style: solid;
            padding: 10px;

            &.has-error {
                outline-color: red !important;
                outline-width: 2px;
                border-color: red !important;
            }

            &:focus {
                outline-color: teal;
                outline-width: 2px;
                border-color: teal;

                &.has-error {
                    outline-color: red !important;
                    outline-width: 2px;
                    border-color: red !important;
                }
            }
        }

        .success-row{
            h3{
                color:teal;
                text-align: left;
            }
            p{
                color:gray;
            }
        }

        .error-container {
            display: flex;
            align-items: center;
            height: 100%;
            svg {
                display: inline-block;
            }
            p {
                color: red;
                margin: 0px;
                font-size: 14px;
                line-height: 14px;
                display: inline-block;
                margin-left: 4px;
            }
        }

        .button-container {
            input[type='button'] {
                width: 100%;
                height: 42px;
                color: #fff;
                background-color: teal;
                border-radius: 3px;
                border: 0px;
                padding: 10px;
                &:disabled{
                    background-color: gray;
                }
            }
        }

        .login-header {
            font-size: 18px;
            margin-bottom: 0px;
            margin-top: 20px;
        }

        .info-message {
            font-size: 12px;
            font-weight: bold;
            color: gray;
            margin-top: 0px;
        }

        .forgot-password-section {
            margin-top: 20px;
            * {
                margin: 0px;
                margin-top: 4px;
            }
            a {
                color: teal;

                &:visited {
                    color: teal;
                }

                &:hover {
                    color: teal;
                }
            }
        }

        .bottom-container {
            position: absolute;
            bottom: 0px;
            right: 20px;
            a {
                float: right;
                margin: 0px 5px;
                color: gray;

                &:visited {
                    color: gray;
                }

                &:hover {
                    color: lightgray;
                }
            }
        }
    }

    .middle-container > #login-error {
        margin: 0px;
        display: none;
        color: red;
        text-align: center;
    }

    button.link {
        background: none !important;
        border: none;
        padding: 0 !important;

        /*optional*/
        font-family: arial, sans-serif; /*input has OS specific font-family*/
        color: #069;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
}
