.mainBody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.loginHeader {
    font-size: 18px;
    margin-bottom: 0px;
    margin-top: 20px;
}

.infoMessage {
    font-size: 12px;
    font-weight: bold;
    color: gray;
    margin-top: 0px;
}

.successMessage {
    font-size: 18px;
    font-weight: bold;
    color: teal;
    margin-top: 6px;
    margin-left: 30px;
    margin-right: 30px;
}

.separator{
    height: 20px;
}

.row {
    margin-top: 10px;
    width: 100%;

    .successRowSpan{
            font-size: 16px;
    }
}

.activationInput {
    width: 100%;
    height: 42px;
    box-sizing: border-box;
    border: 1px solid gray;
    border-radius: 3px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    outline: 0px;
    outline-color: transparent;
    outline-style: solid;
    padding: 10px;

    &.has-error {
        outline-color: red !important;
        outline-width: 2px;
        border-color: red !important;
    }

    &:focus {
        outline-color: teal;
        outline-width: 2px;
        border-color: teal;

        &.has-error {
            outline-color: red !important;
            outline-width: 2px;
            border-color: red !important;
        }
    }
}

.buttonContainer {
    margin-top: 10px;
    width: 100%;

    input[type='button'] {
        width: 100%;
        height: 42px;
        color: #fff;
        background-color: teal;
        border-radius: 3px;
        border: 0px;
        padding: 10px;

        &:disabled{
            background-color: grey;
        }
    }
}
